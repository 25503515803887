import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'
import {MainText} from "../../components/MainComponents";
import Helmet from "react-helmet";

export default class BlogIndexPage extends React.Component {
    render() {
        return (
            <Layout>
                <Helmet titleTemplate="Přečti si novinky v oblasti kyberbezpečnosti | TunaSec">
                    {/* General tags */}
                    <title>Přečti si novinky v oblasti kyberbezpečnosti | TunaSec</title>
                    <meta name="robots" content="index, follow" />
                    <meta name="keywords" content="bezpečnost, it security, it bezpečnost, blog, kyberbezpečnost" />
                    <meta property="og:title" content="Přečti si novinky v oblasti kyberbezpečnosti | TunaSec"/>
                    <meta name="description" content="Píšeme blogové články pro širokou veřejnost v oblasti kyberbezpečnosti. Všechny články, které jsme vydali si přečteš zde." />
                    <meta name="og:description" content="Píšeme blogové články pro širokou veřejnost v oblasti kyberbezpečnosti. Všechny články, které jsme vydali si přečteš zde." />
                    <meta name="og:image" content="https://tunasec.com/img/og-images/blog.png" />
                </Helmet>
                <div className="">
                    <div
                        className="full-width-image margin-top-0"
                        style={{
                            backgroundImage: `url("/img/layout/background-main.svg"), linear-gradient( 112.1deg,  rgba(32,38,57,1) 11.4%, rgba(63,76,119,1) 70.2% )`,
                            backgroundPosition: `center`,
                            backgroundSize: "1920px",
                            height: "350px",
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                height: '150px',
                                lineHeight: '1',
                                justifyContent: 'space-around',
                                alignItems: 'left',
                                flexDirection: 'column',
                            }}
                        >
                            <MainText>Blogové články</MainText>
                        </div>
                    </div>
                </div>
                <section className="section">
                    <div className="container">
                        <div className="content">
                            <BlogRoll/>
                        </div>
                    </div>
                </section>
                <div style={{marginBottom: "100px"}}/>
            </Layout>
        )
    }
}
